<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import { mapState } from "vuex";
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import Password from "@/components/widgets/input-password";

export default {
  locales: {
    pt: {
      'My Password': 'Minha Senha',
      'Old password': 'Senha antiga',
      'New password': 'Nova senha',
      'Confirm password': 'Confirmar senha',
      'Save': 'Salvar',
    },
    es: {
      'My Password': 'Mi Contraseña',
      'Old password': 'Contraseña Anterior',
      'New password': 'Nueva Contraseña',
      'Confirm password': 'Confirmar Contraseña',
      'Save': 'Salvar',
    }
  },
  components: {
    Layout,
    Password
  },
  computed: {
    ...mapState({
      account: state => state.account
    })
  },
  data() {
    return {
      alert: {
        password: { type: '', message: '' }
      },

      password: { old: "", newest: "", confirm: "" },
    };
  },
  validations: {
    password: {
      old: { required, minLength: minLength(6) },
      newest: { required, minLength: minLength(6) },
      confirm: { required, minLength: minLength(6), sameAs: sameAs('newest') }
    }
  },
  methods: {
    passwordSubmit() {
      this.$v.password.$touch();
      if (this.$v.password.$invalid) {
        return;
      } else {
        const { old, newest, confirm } = this.password;
        if (old && newest && confirm) {
          api
            .post('user/password', {
              old: this.password.old,
              new: this.password.newest,
              confirm: this.password.confirm
            })
            .then(response => {
              if (response.data.status == 'success') {
                this.password.old = ''
                this.password.newest = ''
                this.password.confirm = ''
                this.$v.password.$reset()

                this.alert.password.type = 'alert-success'
                this.alert.password.message = response.data.message
              } else {
                this.alert.password.type = 'alert-danger'
                this.alert.password.message = response.data.message
              }
            })
        }
      }
    },
  },
  mounted() {
  },
};
</script>

<template>
  <Layout>
    <div class="page-title-box d-md-flex justify-content-between">
      <div>
        <h3>{{ t('My Password') }}</h3>
        <p></p>
      </div>
    </div>

    <div v-if="alert.password.message" :class="'alert ' + alert.password.type">{{ t(alert.password.message) }}</div>
    <div class="card">
      <div class="card-body table-responsive">
        <b-form class="p-3" @submit.prevent="passwordSubmit">
          <b-form-group id="password-old" :label="t('Old password')" label-for="password-old">
            <Password v-model="password.old" :v="$v.password.old"></Password>
          </b-form-group>
          <b-form-group id="password-newest" :label="t('New password')" label-for="password-newest">
            <Password v-model="password.newest" :v="$v.password.newest"></Password>
          </b-form-group>
          <b-form-group id="password-confirm" :label="t('Confirm password')" label-for="password-confirm">
            <Password v-model="password.confirm" :v="$v.password.confirm"></Password>
          </b-form-group>
          <div class="mt-4">
            <b-button :disabled="!this.password.old || !this.password.newest || !this.password.confirm" type="submit" variant="default">{{ t('Save') }}</b-button>
          </div>
        </b-form>
      </div>
    </div>
  </Layout>
</template>